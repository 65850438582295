import React from "react"
import Header from "../components/header"
import * as H from "../components/headings"
import * as C from "../components/containers"
import * as Text from "../components/text"
import * as U from "../components/ui"

export default props => (
  <div>
    <Header/>
    <C.centered>
      <H.h1>Buy Art</H.h1>
      <Text.p>You can browse and buy prints from my Etsy shop.</Text.p>
      <a href={"https://www.etsy.com/uk/shop/CrowandApricot"} target={"_blank"} rel="noopener noreferrer"><U.button sx={{ width: "20%", mx: "auto" }}>Visit My Etsy Shop</U.button></a>
    </C.centered>
    <C.bioText>
      <Text.p>I often produce bespoke pieces, and that’s where it gets really fun. If you would like to commission me to
        create a special one-off piece, either as a gift for someone or for your own home, then send me a message via my
        contact page for an informal chat about your idea.</Text.p>
      <Text.p>I love doing commissions for people; the dialogue between the artist and client is enjoyable and
        collaborative,
        where you get to be a part of the creation of something deeply personal to you, and with a unique meaning. The
        outcome generates much joy for everyone involved, the recipient especially.</Text.p>
      <Text.p>In between running around after my children, I just about find time to exhibit, and I also sell my art on
        a
        stall at various art fairs in and around the county. Follow me on Instagram, Facebook or check in with my blog
        now and again for dates when these might be. They’re a great way of seeing the art first-hand, as well as
        meeting the artist in person.</Text.p>
    </C.bioText>
  </div>
);